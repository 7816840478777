export const environment = {
  name: 'testing',
  production: false,
  backend_url: 'https://ey-parking-api-testing.attrecto-dev.com/api/',
  firebase: {
    apiKey: 'AIzaSyCfS0ULkqIQolmu54YXravu4BNS7iUDcMU',
    authDomain: 'ey-parking-dev.firebaseapp.com',
    projectId: 'ey-parking-dev',
    storageBucket: 'ey-parking-dev.appspot.com',
    messagingSenderId: '1096382483222',
    appId: '1:1096382483222:web:827ba527b03d8316f6de37',
  },
};
